<template>
  <b-section class="hero-section moving section-padding" id="home">
    <div class="moving-bg"></div>
    <!-- Hero Content -->
    <div class="hero-section-content">
      <b-container>
        <div class="row align-items-center">
          <!-- Welcome Content -->
          <b-col col="12" lg="6" md="12">
            <div class="welcome-content">
              <div class="promo-section">
                <h3 class="special-head gradient-text">
                  We ❤️ ducks!!
                </h3>
              </div>
              <h1>
                Free the Ducks with
                <span class="gradient-text">Duck Coin</span>
              </h1>
              <p class="w-text">
                Duck coin is the first token that goal is to make every duck a happy, wild, and free from any constraint
                animal.
                Ducks are magestic creatures that deserve to live their own life, in the wild, and you can help them!
              </p>
              <!--              <div class="dream-btn-group">
                              <base-button mode="btn more-btn me-3">how to buy</base-button>
                            </div>-->
            </div>
          </b-col>
          <b-col lg="6"></b-col>
        </div>
      </b-container>
    </div>
  </b-section>

  <b-section class="hero-section moving section-padding">
    <div class="static-bg" id="what-duckcoin"></div>
    <!-- Hero Content -->
    <div class="hero-section-content">
      <b-container>
        <div class="row align-items-center">
          <!-- Welcome Content -->
          <b-col col="12" lg="6" md="12">
            <div class="welcome-content">
              <div class="promo-section">
                <h3 class="special-head gradient-text">
                  What's Duck Coin ?
                </h3>
              </div>
              <h1>
                <span class="gradient-text">Duck Coin</span>
                is good!
              </h1>
              <p class="w-text">
                DogeCoin token main goal is to help the cutest of all the palmipeds! <span
                  class="gradient-text">30%</span> of the transaction fees will be
                sent
                to association that will help to stop battery breeding
              </p>
            </div>
          </b-col>
          <b-col lg="6"></b-col>
        </div>
      </b-container>
    </div>
  </b-section>

  <section class="section-padding-0-70 clearfix" style="margin-top: 120px">
    <div class="container">

      <div class="section-heading text-center">

        <div id="ecosystem-info" class="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
          <span style="font-size: 36px;" class="ecosystem">DuckCoin ecosystem</span>
        </div>
      </div>


      <div class="row space-between">
        <!--        <div class="col-12 col-md-4 col-lg-4">

                  <div
                     style="margin-bottom: 0px; visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"
                     class="service_single_content collection-item wow fadeInUp" data-wow-delay="0.2s">
                    <div class="collection_icon" style="text-align: center; padding-top: 1rem;">
                      <div class="ecosystem-image burnt"></div>
                    </div>
                    <div class="collection_info" style="text-align: center;">
                      <h4>Burnt</h4>
                      <p>60% - <span class="w-text">57 829 353</span> were burned</p>
                    </div>

                  </div>
                </div>-->
        <div class="col-12 col-md-4 col-lg-4">

          <div style="margin-bottom: 0px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
               class="service_single_content collection-item wow fadeInUp" data-wow-delay="0.3s">
            <div class="collection_icon" style="text-align: center; padding-top: 1rem;">

              <div class="ecosystem-image liquidity"></div>
            </div>
            <div class="collection_info" style="text-align: center;">
              <h4>DuckCoin tokens</h4>
              <p>10% - <span class="w-text">9 638 225</span> for liquidity</p>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <div style="margin-bottom: 0px; visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"
               class="service_single_content collection-item wow fadeInUp" data-wow-delay="0.4s">
            <div class="collection_icon" style="text-align: center; padding-top: 1rem;">

              <div class="ecosystem-image airdrop"></div>
            </div>
            <div class="collection_info" style="text-align: center;">
              <h4>Airdrop</h4>
              <p>30% - <span class="w-text">28 914 676</span> will be dropped soon</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<style scoped>
.hero-section {
  position: relative;
  min-height: 700px;
  background: transparent no-repeat bottom center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section.moving {
  overflow: hidden;

  background-size: cover;
}

.static-bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 480px;
  height: 480px;
  opacity: 0.2;
  border-radius: 15px;
  visibility: inherit;
  background: transparent url("https://media0.giphy.com/media/wsUtUtLR3A2XPvfLVs/giphy.gif?cid=790b76116061ccadc6680051779118ddfd9b223ac1e15576&rid=giphy.gif&ct=g") no-repeat;
  background-size: auto;
}

@media (max-width: 500px) {
  .hero-section.moving {
    background-position: left top;
  }

  .static-bg {
    left: 0;
  }
}

.moving-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500em;
  height: 70%;
  opacity: 0.2;
  visibility: inherit;
  background: transparent url("~@/assets/img/core-img/crypto.png") repeat-x scroll 0 100%;
  -webkit-animation: cloudwash 60s linear infinite;
  -moz-animation: cloudwash 60s linear infinite;
  animation: cloudwash 60s linear infinite;
}

@-webkit-keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

.welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.promo-section {
  margin-bottom: 30px;
}

.special-head {
  color: #28cc8b;
  padding-left: 40px;
  font-size: 18px;
  position: relative;
}

.special-head:before {
  content: "";
  background: #3964f9;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}

.gradient-text {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #846ff4 0%, #f17674 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.gradient-text.blue {
  background-image: linear-gradient(35deg, #17ead9 0%, #6078ea 100%);
}

.ecosystem {
  color: #ffffff;
}

.ecosystem-image {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.ecosystem-image.burnt {
  background: red;
  -webkit-mask: url("~@/assets/img/crypto-info/burnt.png") center/contain;
  mask: url("~@/assets/img/crypto-info/burnt.png") center/contain;
}

.ecosystem-image.liquidity {
  background: #04d0ff;
  -webkit-mask: url("~@/assets/img/crypto-info/liquidity.png") center/contain;
  mask: url("~@/assets/img/crypto-info/liquidity.png") center/contain;
}

.ecosystem-image.airdrop {
  background: linear-gradient(135deg, #846ff4 0%, #f17674 100%);
  -webkit-mask: url("~@/assets/img/crypto-info/airdrop.png") center/contain;
  mask: url("~@/assets/img/crypto-info/airdrop.png") center/contain;
}

.collection-item {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.service_single_content {
  position: relative;
  z-index: 1;
  transition: all .3s ease-in-out;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.space-between {
  place-content: space-evenly;
}

#ecosystem-info {
  margin: 50px;
}

</style>



