<template>
  <footer class="main-footer text-center">
    <!--Widgets Section-->
    <div id="community" class="widgets-section padding-top-small padding-bottom-small">
      <div class="container">

        <div class="row clearfix">
          <!--Footer Column-->
          <div class="footer-column col-md-3 col-sm-6 col-xs-12">
          </div>
          <div class="footer-column col-md-6 col-sm-6 col-xs-12">
            <div class="footer-widget contact-widget">
              <h3 style="margin-bottom: 5px;">Community</h3>
              <h3 style="margin-top: 5px;" class="has-line-center">Be part of the duck loving community, join the Pond</h3>
              <div class="widget-content">
                <a style="margin-right: 1rem;" target="_blank" href="https://discord.gg/34NUFB7u3h">
                  <img style="max-width: 100px;" src="~@/assets/img/social/discord.png">
                </a>
                <a style="margin-left: 1rem;" target="_blank" href="https://twitter.com/DuckCoinSolana">
                  <img style="max-width: 100px;" src="~@/assets/img/social/twitter.png">
                </a>
              </div>
              <h3>We're eager to quack with you!</h3>
            </div>
          </div><!--End Footer Column-->

        </div>
      </div>
    </div>

    <!--Footer Bottom-->
    <div class="footer-bottom">
      <div class="auto-container">
        <div class="copyright-text">DuckCoin © Copyright duckcoin.co All Rights Reserved</div>
      </div>
    </div>
  </footer>
</template>
<script>
/*import SocialBox from "../Helper/SocialBox.vue";*/
export default {
  components: {
    /*SocialBox,*/
  },
};
</script>
<style scoped>
.main-footer {
  position: relative;
  background: #020c31;
}
.main-footer .has-line-center:after {
  width: 30px;
  background: #b06ab3;
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  margin: 15px auto;
}
.main-footer h3 {
  color: #fff;
  font-size: 20px;
  margin: 30px 0 25px;
  position: relative;
}
.main-footer .widgets-section {
  position: relative;
  color: #bbbbbb;
  padding: 50px 0 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: url("~@/assets/img/core-img/map-pattern-footer.png") center 75%
    no-repeat;
}

.main-footer .footer-widget {
  position: relative;
}
.main-footer .about-widget .text {
  position: relative;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1.9em;
}

.main-footer .newsletter-widget .text {
  position: relative;
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 1.9em;
}

.social-links {
  position: relative;
}

.social-links li {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.social-links li {
  margin: 0px 2px;
}

.social-links li a {
  position: relative;
  display: block;
  line-height: 38px;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 13px;
  background: rgba(255, 255, 255, 0.05);
  color: #bbbbbb;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  transition: all 500ms ease;
}

.social-links li a:hover {
  color: #ffffff;
}

.main-footer .about-widget .contact-info {
  margin-bottom: 30px;
}

.main-footer .contact-info li {
  position: relative;
  display: block;
  font-size: 14px;
  margin-bottom: 7px;
  line-height: 24px;
}

.main-footer .contact-info li .icon {
  position: relative;
  font-size: 32px;
  margin: 0 auto 20px;
  line-height: 36px;
  color: #b06ab3;
}

.main-footer .contact-info li:last-child {
  margin-bottom: 0px;
}

.main-footer .footer-bottom {
  position: relative;
  padding: 18px 0px;
  color: #ccc;
  background: #020a27;
  line-height: 24px;
  text-align: center;
}

.main-footer .newsletter-widget {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.main-footer .newsletter-widget .form-group {
  position: relative;
  margin: 0px;
}

.main-footer .newsletter-widget input[type="email"] {
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 10px 40px 10px 25px;
  height: 48px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
  border-radius: 24px;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-footer .newsletter-widget .send-btn {
  position: absolute;
  right: 10px;
  top: 0px;
  width: 50px;
  height: 48px;
  outline: none;
  border: none;
  line-height: 48px;
  text-align: center;
  color: #b06ab3;
  font-size: 16px;
  background: none;
  cursor: pointer;
}

.main-footer .links-widget .list li {
  position: relative;
  margin-bottom: 12px;
}

.main-footer .links-widget .list li a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 0px 0px 0px 25px;
  color: #aaaaaa;
  text-transform: capitalize;
}
</style>
