<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-white fixed-top" id="banner">
      <div class="container">
        <!-- Brand -->
        <a class="navbar-brand" href="#">
          <span><img src="@/assets/img/core-img/logo.png" alt="logo" /></span>
          DuckCoin</a
        >

        <!-- Toggler/collapsible Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="#what-duckcoin">What's DuckCoin?</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="#ecosystem-info">Ecosystem</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="#community"
                >Community</router-link
              >
            </li>
<!--            <li class="lh-55px">
              <base-button route="/walletConnect" mode="btn login-btn ml-50">
                How to Buy
              </base-button>
            </li>-->
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>


<script>
export default {
  components: {},
  methods: {
    whenScroll() {
      if (document.documentElement.scrollTop > 86) {
        document.getElementById("banner").classList.add("shrink");
      } else {
        document.getElementById("banner").classList.remove("shrink");
      }
    },
    collapse() {
      const links = document.querySelectorAll(
        ".dropdown-item , .nav-link:not(.dropdown-toggle)"
      );
      console.log(links);
      const navbarCollapse = document.querySelector(".navbar-collapse");
      links.forEach((link) => {
        link.addEventListener("click", function () {
          navbarCollapse.classList.remove("show");
        });
      });
    },
  },

  mounted() {
    window.addEventListener("scroll", this.whenScroll);
    this.collapse();
  },
};
</script>



<style scoped>
html {
  height: 2000px;
}
nav {
  transition: all 0.5s ease-in;
}

.navbar-brand {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
}
.lh-55px {
  line-height: 55px;
}
.navbar-nav .nav-item .nav-link {
  padding: 20px 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  /*color: #fff;*/
}
.navbar-nav .nav-item .nav-link:hover {
  color: #f4dc31;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
  border-top: 3px solid #fed136;
}

nav {
  -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
  -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
  transition: padding-top 0.3s, padding-bottom 0.3s;
  border: none;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.navbar-white .navbar-brand:hover {
  color: #f4dc31
}
.navbar-dark .navbar-brand:hover {
  color: #333;
}

/* mobile view */
@media (max-width: 500px) {
  .navbar {
    background-color: rgba(2, 12, 49, 0.7);
  }
  .navbar-nav {
    border-radius: 15px;
    z-index: 1;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 100%;
    font-weight: 500;
  }
}

.navbar-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>");
}
@media (max-width: 992px) {
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    padding: 5px 12px;
    color: #ffffff;
  }
  .navbar-collapse {
    border-radius: 15px;
    margin-top: 5px;
    padding-top: 5px;
  }
}

.navbar-white.shrink .navbar-brand,
.shrink .navbar-nav .nav-item .nav-link {
  color: #333;
}

.shrink {
  transition: transform;
  transition-duration: 0.4s;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #333;
}

.navbar-cyan .navbar-brand,
.navbar-cyan .navbar-nav .nav-item .nav-link {
  color: #04d0ff;
}
.navbar-collapse {
  justify-content: flex-end;
}
</style>
