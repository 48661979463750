import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import NotFound from '../pages/NotFound.vue'

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, top: 200 }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/home', component: Home },

    { path: '/:notfound(.*)', component: NotFound, meta: { NotFound: true } },
  ],
})
router.beforeEach(function (_, _2, next) {
  setTimeout(() => {
    window.scrollTo(0, 0)
    next()
  }, 10)
})
export default router
